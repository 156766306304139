<template>
    <div class="record">
        <header class="record-header">
            <h1>电子病历系统分级评价</h1>
        </header>
        <div class="record-content">
            <div class="one record-item" v-if="data.one != void 0">
                <module-title :title="data.one.title" />
                <div class="recard-one">
                    <p class="content">{{ data.one.content }}</p>
                    <img class="one-img" :src="data.one.img" alt="" />
                </div>
            </div>
            <div class="two record-item" v-if="data.two != void 0">
                <module-title :title="data.two.title" />
                <div class="recard-tow">
                    <ul class="recard-list">
                        <li v-for="(item, i) in data.two.data" :key="i">
                            <img :src="item.icon" alt="" />
                            <div>{{ item.title }}</div>
                        </li>
                    </ul>
                    <div class="recard-box">
                        <p>{{ data.two.content }}</p>
                        <img :src="data.two.img" alt="">
                    </div>
                </div>
            </div>
            <div class="three record-item" v-if="data.three != void 0">
                <module-title :title="data.three.title" />
                <img :src="data.three.img" alt="" />
            </div>
            <div class="four record-item" v-if="data.four != void 0">
                <module-title :title="data.four.title" />
                <ul class="list">
                    <li v-for="(item, i) in data.four.data" :key="i">
                        <img :src="item.img" alt="" />
                        <p>{{ item.title }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    // 电子病历系统分级评价
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';

    // vuex
    import { mapMutations } from 'vuex';

    // api
    import { getRecord } from "@/api/request";

    export default {
        name: 'record',
        created () {
            this.getRecordAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                "setBusinessBanners"
            ]),
            getRecordAjax () {
                getRecord().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        },
        components: {
            ModuleTitle
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .record {
        .record-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .record-content {
            padding: 50px 40px;
            .record-item {
                margin-bottom: 100px;
                &:last-child {
                    margin-bottom: 0;
                }
                &.three {
                    img {
                        margin-top: 50px;
                    }
                }
                &.four {
                    .list {
                        display: flex;
                        align-items: flex-start;
                        margin-top: 50px;
                        font-size: 14px;
                        li {
                            flex: 1;
                            position: relative;
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                            p {
                                position: absolute;
                                bottom: 2px;
                                left: 0;
                                right: 0;
                                padding: 10px;
                                color: #FFF;
                                background-color: rgba(0, 0, 0, 0.5);
                            }
                        }
                    }
                }
                .recard-one {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    position: relative;
                    top: -20px;
                    .content {
                        margin-right: 20px;
                        line-height: 1.5;
                    }
                }
                .recard-tow {
                    font-size: 14px;
                    .recard-list {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-top: 40px;
                        li {
                            margin-right: 20px;
                            margin-bottom: 20px;
                            width: calc(~'(100% - 80px) / 5');
                            text-align: center;
                            color: @theme-color;
                            &:nth-child(5n) {
                                margin-right: 0;
                            }
                            &:nth-last-child(-5 + n) {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .recard-box {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        p {
                            margin-right: 40px;
                            line-height: 1.5;
                            color: #666;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .record {
            .record-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .record {
            .record-content {
                padding: 0.8rem 0.5333rem;
                .record-item {
                    margin-bottom: 50px;
                    .recard-one {
                        position: static;
                        flex-direction: column;
                        margin-top: 20px;
                        font-size: 12px;
                        .content {
                            margin-right: 0;
                        }
                        img {
                            margin-top: 16px;
                            width: 100%;
                        }
                    }
                    .recard-tow {
                        font-size: 12px;
                        .recard-box {
                            flex-direction: column;
                            p {
                                margin: 0 0 20px 0;
                            }
                        }
                    }
                    &.four {
                        .list {
                            flex-direction: column;
                            font-size: 12px;
                            li {
                                margin-bottom: 20px;
                                width: 100%;
                                img {
                                    width: 100%;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>